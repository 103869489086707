import HeroSection from '../HeroSection/HeroSection';
import Footer from '../Footer/Footer';

function Home() {
  return (
    <>
      <HeroSection />
      <Footer />
    </>
  );
}

export default Home;